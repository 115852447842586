import React, { useEffect, useRef, useState } from "react";
import styles from "./TemplateList.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@tanstack/react-query";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import Checkbox from "@mui/material/Checkbox";
import WarningIcon from "@mui/icons-material/Warning";
import PublicIcon from '@mui/icons-material/Public';
import Groups2Icon from '@mui/icons-material/Groups2';
import Lottie from "lottie-react";
import videoLoadingAnimation from "../animation/Animation - 1741783692232.json";

const TemplateList = ({
  defaultTemplates,
  nonDefaultTemplates,
  onSelect,
  isLoading,
  selectedTemplateId,
  globalTemplates,
}) => {
  const templateRefs = useRef({});
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [filter, setFilter] = useState("all");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);

  const deleteConsentTemplate = useMutation(
    (id) => axios.delete(`/consent_clause/${id}`),
    {
      onSuccess: (data, id) => {
        if (selectedTemplateId === id) {
          onSelect(null);
        }
        window.location.reload();
      },
    }
  );

  const handleDelete = (e, templateId) => {
    e.stopPropagation();
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this consent clause?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteConsentTemplate.mutate(templateId);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const Deletebulk = useMutation(
    () =>
      axios.post(`/consent_clause/bulk_archive_consent_clauses`, {
        ids: selectedTemplates, 
      }),
    {
      onSuccess: () => {
        console.log("Archived templates:", selectedTemplates);
        setSelectedTemplates([]);
        window.location.reload();
      },
      onError: (error) => {
        console.error("Error archiving templates:", error);
      },
    }
  );

  const handleDeletebulk = () => {
    console.log("Checked IDs:", selectedTemplates);

    confirmAlert({
      title: "Confirm Bulk Archive",
      message: `Are you sure you want to archive the selected templates?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Deletebulk.mutate();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const cloneConsentTemplate = useMutation(
    (clause) => axios.put(`/consent_clause/${clause.id}/clone`),
    {
      onSuccess: async (data, clause) => {
        setCurrentTemplate(data.data);
        scrollToTop();
        setTimeout(() => {
          onSelect(data.data);
        }, 2000);

        try {
          const { data: updatedTemplates } = await axios.get(
            `/consent_clause?=`
          );
          onSelect(updatedTemplates);
        } catch (error) {
          console.error("Error fetching updated templates:", error);
        }
      },
    }
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollToSelectedTemplate = () => {
      if (selectedTemplateId && templateRefs.current[selectedTemplateId]) {
        templateRefs.current[selectedTemplateId].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    };

    if (selectedTemplateId) {
      const timeoutId = setTimeout(scrollToSelectedTemplate, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedTemplateId]);

  // useEffect(() => {
  //   if (!selectedTemplateId && defaultTemplates.length > 0) {
  //     onSelect(defaultTemplates[0]);
  //   }
  // }, [defaultTemplates, selectedTemplateId, onSelect]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative' }}>
        <Lottie animationData={videoLoadingAnimation} style={{ width: 350, height: 350 }} />
        <img
          src="/logo.png" 
          alt="Overlay image"
          style={{
            position: 'absolute',
            top: '45%',  // Adjusted value to move the image upwards
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 200, // adjust as necessary
            height: 90, // adjust as necessary
          }}
        />
      </div>
    );
  }

  const handleTemplateClick = (template) => {
    setSelectedTemplateIds((prev) =>
      prev.includes(template.id)
        ? prev.filter((id) => id !== template.id)
        : [...prev, template.id]
    );

    onSelect(template);
  };

  const handleCheckboxChange = (templateId) => {
    setSelectedTemplates((prevSelected) =>
      prevSelected.includes(templateId)
        ? prevSelected.filter((id) => id !== templateId)
        : [...prevSelected, templateId]
    );
  };

  const filteredTemplates = (() => {
    if (filter === "default") {
      return defaultTemplates;
    } else if (filter === "archived") {
      return nonDefaultTemplates;
    }
    else if (filter === "global") {
      return globalTemplates;
    }
    return [...defaultTemplates, ...nonDefaultTemplates, ...globalTemplates];
  })();

  

  return (
    <div className={styles.templateListBox}>
      {selectedTemplates.length > 0 && (
        <div className={styles.filterButtons}>
          <div className={styles.actionButtonContainer}>
            <button
              className={styles.actionButton}
              onClick={() => handleDeletebulk()}
            >
              <span className={styles.buttonContent}>
                Delete Selected <WarningIcon />
              </span>
            </button>
          </div>
        </div>
      )}

      <div className={styles.filterButtons}>
        <button
          onClick={() => setFilter("all")}
          className={`${styles.filterButton} ${
            filter === "all" ? styles.active : ""
          }`}
        >
          All
        </button>
        <button
          onClick={() => setFilter("default")}
          className={`${styles.filterButton} ${
            filter === "default" ? styles.active : ""
          }`}
        >
          Default
        </button>
        <button
          onClick={() => setFilter("global")}
          className={`${styles.filterButton} ${
            filter === "global" ? styles.active : ""
          }`}
        >
          Global
        </button>
        <button
          onClick={() => setFilter("archived")}
          className={`${styles.filterButton} ${
            filter === "archived" ? styles.active : ""
          }`}
        >
          Archived
        </button>
      </div>

      <div className={styles.scrollableContainer}>
        {filteredTemplates.map((template) => (
          <div
            key={template.id}
            className={`${styles.listItem} ${
              template.type === "intro" || template.type === "outro"
                ? styles.highlighted
                : ""
            }`}
            onClick={() => handleTemplateClick(template)}
            ref={(el) => (templateRefs.current[template.id] = el)}
            style={{
              backgroundColor:
                template.id === selectedTemplateId ? "#e0f7fa" : "",
            }}
          >
            {template.type !== "intro" && template.type !== "outro" && template.level !== "global" &&(
              <div className={styles.checkboxContainer}>
                <Checkbox
                  checked={selectedTemplates.includes(template.id)}
                  onChange={() => handleCheckboxChange(template.id)}
                />
              </div>
            )}
            <div className={styles.leftSide}>
              {template.is_default && (
                <DoneIcon className={styles.defaultIcon} />
              )}
              {template.level === "user" ? (
                <PersonIcon className={styles.userIcon} />
              ) : template.level === "practice" ? (
                <Groups2Icon className={styles.buildingIcon} />
              ) : (
                <div className={styles.publicContainer}>
                  <img src="/logo_mono.png" alt="small icon" className={styles.smallImage} />
               
                </div>
              )}
            </div>
            <span className={styles.templateName}>{template.name}</span>
            {template.level !== "global" && (
                <div className={styles.rightSide}>
                  {!(template.level === "practice" && (template.type === "intro" || template.type === "outro")) && ( 
                  
                    <button
                      className={styles.deleteButton}
                      onClick={(e) => handleDelete(e, template.id)}
                    >
                      <DeleteIcon className={styles.deleteIcon} />
                    </button>
                  )}
                  {!["intro", "outro"].includes(template.type) && ( 
                  
                    <FileCopyIcon
                      className={styles.copyIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        cloneConsentTemplate.mutate(template);
                      }}
                    />
                  )}
                </div>
              )}



          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateList;
