import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from '@mui/material';
import MainCard from '../../components/MainCard';
import Sidebar from "../../menu-items/Sidebar";
import axios from 'axios';

const Waitlist = () => {
  const [data, setData] = useState([]); 
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(20); 

  useEffect(() => {
    const fetchData = async () => {

      const token = axios.defaults.headers.common.Authorization;
      const url = `${process.env.REACT_APP_BASE_URL}admin/reports/waitinglist`;
;
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      try {
        const response = await axios.get(url, config);
     
        setData(response.data);
      
      } catch (error) {
        console.error('Error fetching data:', error);  
      }
    };

    fetchData();  
  }, []);

  const downloadAsCSV = () => {
    const csvRows = [];
    const headers = ['#', 'Email', 'Created At', 'Role', 'Actions'];
    csvRows.push(headers.join(','));

    data.forEach((row, index) => {
      const rowData = [
        page * rowsPerPage + index + 1,
        row.email,
        row.created_at,
        row.treatment_plan_id,
        'Contact' 
      ];
      csvRows.push(rowData.join(','));
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'waitlist.csv';
    link.click();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={9} lg={10}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold', 
                fontSize: '2rem', 
              }}
            >
            Waiting list
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: 2 }}
              onClick={downloadAsCSV}
            >
              Download as CSV
            </Button>
            
          </Box>

          <MainCard title="">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: '15px', 
                boxShadow: 3, 
                overflow: 'hidden', 
                marginTop: 2,
              }}
            >
              <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell> 
                    <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Role</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
              {displayedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                displayedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                        cursor: 'pointer',
                      },
                      borderBottom: '1px solid #ddd', 
                    }}
                  >
                    <TableCell sx={{ borderTopLeftRadius: index === 0 ? '15px' : '0px' }}>
                      {page * rowsPerPage + index + 1} 
                    </TableCell>
                    
                    <TableCell>
                      {row.email}
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ marginLeft: 1 }}
                        onClick={() => navigator.clipboard.writeText(row.email)} 
                      >
                        Copy
                      </Button>
                    </TableCell>
                    <TableCell>{row.created_at}</TableCell>
                    <TableCell>{row.treatment_plan_id}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ marginLeft: 1 }}
                        onClick={() => console.log(`Contacting ${row.email}`)} 
                      >
                        Contact
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Waitlist;
