import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import cx from "classnames";
import styles from "./Dashboard.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CreateConsentForm from "./CreateConsentForm";
import queryString from "query-string";
import ConsentLogs from "./ConsentLogs";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { confirmAlert } from "react-confirm-alert";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { getPdfName } from "../utils/utils";
import { Modal } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useEffect, useRef } from "react";
import { CheckCircle, Error } from '@mui/icons-material';
import Cookies from "js-cookie";
import { Tooltip } from '@mui/material';

import QuickviewIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import PrivacyPolicyModal from "./privacyPolicy/PrivacyPolicyModal";
import SaasModal from "./privacyPolicy/SaasModel";
import EulaModal from "./privacyPolicy/eulaModal";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { Switch, FormControl, InputLabel} from "@mui/material";
import Lottie from "lottie-react";
import videoLoadingAnimation from "./animation/Animation - 1741783692232.json";

export default function Dashboard() {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const [patients, setPatients] = React.useState<any[]>([]);
  const [patientsList, setPatientsList] = React.useState<any[]>([]);
  const [isOpenCreateConsentModal, setIsOpenCreateConsentModal] =
    React.useState<boolean>(false);
  const [consentLogsModalId, setConsentLogsModalId] = React.useState("");
  const [patient, setPatient] = React.useState<number>();
  const [inputValue, setInputValue] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState("Date Created DESC");
  const [status, setStatus] = React.useState("");
  const [isNonUploaded, setIsNonUploaded] = React.useState(false);
  const [isMysite, setIsMysite] = React.useState(false);
  const [isTodayOnly, setIsTodayOnly] = React.useState(false);
  const [duein24Hours, setDueIn24Hours] = React.useState(false);
  const initialLoad = useRef(true);
  const [bulkActionSelectedList, setBulkActionSelectedList] = React.useState(
    []
  );
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [downloadingConsentId, setDownloadingConsentId] = React.useState<
    string | null
  >(null);
  const [clinicianList, setClinicianList] = React.useState([]);
  const [selectedClinician, setSelectedClinician] = React.useState("");
 

  const treatmentDetails = useQuery(
    [
      "patients",
      patient,
      query,
      page,
      status,
      sort,
      isNonUploaded,
      isMysite,
      isTodayOnly,
      duein24Hours,
      selectedClinician,
    ],
    async () => {
      const params = {
        page,
        per_page: 25,
        patient,
        query,
        sort_by: sort,
        consent_status: status,
        non_uploaded_only: isNonUploaded,
        my_site:isMysite,
        today_only: isTodayOnly,
        due_tomorrow_only: duein24Hours,
        clinician: selectedClinician,
      };
      const stringified = queryString.stringify(params);
      const { data } = await axios.get(`/consent_bundle?${stringified}`);
      console.log("stringified",data);
      const token = axios.defaults.headers.common.Authorization;
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      const practiceURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/practices`;
      const practiceResponse = await axios.get(practiceURL, config);

      const clinicianURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/clinicians?practice=${practiceResponse.data[0].value}`;
      const clinicianResponse = await axios.get(clinicianURL, config);
      setClinicianList(clinicianResponse.data);

      return data;
    },
    {
      onSuccess: (data) => {
        const plans = data.data.map((patient) => ({
          ...patient,
          label: `${patient.patient?.first_name} ${patient.patient?.last_name}`,
        }));
        setPatients(plans);
      },
    }
  );

  //today tab
  useEffect(() => {
    if (!initialLoad.current && treatmentDetails.data?.data.length === 0) {
      toast.warning("No records found");
    }
    if (initialLoad.current) {
      initialLoad.current = false;
    }
  }, [treatmentDetails.data]);

  const [user, setUser] = React.useState("");
  const [userIni, setUserIni] = useState(false);
  const [state, setState] = React.useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showSAASModal, setShowSAASModal] = useState(false);
  const [showEulaModal, setShoweulaModal] = useState(false);
  const [privacyContent, setPrivacyContent] = useState("");
  const [SAASContent, setSAASContent] = useState("");
  const [eulaContent, setEulaContent] = useState("");
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [sortDirection, setSortDirection] = useState<string>("");
  const [sortedPatients, setSortedPatients] = useState<any[]>([]);


  useEffect(() => {
    const fetchData = async () => {
      await getPracticeConsentClauseData();
      await getMyPlan();
    };
    fetchData();
  }, []);



  const handleHeaderSort = () => {
    let newSortDirection = "asc"; 
    if (sortDirection === "asc") {
      newSortDirection = "desc"; 
    } else if (sortDirection === "desc") {
      newSortDirection = "asc"; 
    }
    setSortDirection(newSortDirection);
  };
  

  const handleSort = (value: string) => {
   
    setSortDirection(value);
    setSortedPatients((prevPatients) => {
      return [...prevPatients].sort((a, b) => {
        if (value === "asc") {
          return a.total_treatment_cost - b.total_treatment_cost;
        } else if (value === "desc") {
          return b.total_treatment_cost - a.total_treatment_cost;
        } else if (value === "Date Created DESC") {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        }
        return 0; 
      });
    });
  };
  
  
  useEffect(() => {
    if (patients && patients.length > 0) {
      const sorted = [...patients];
  
      if (sortDirection === "asc") {
        sorted.sort((a, b) => a.total_treatment_cost - b.total_treatment_cost);
      } else if (sortDirection === "desc") {
        sorted.sort((a, b) => b.total_treatment_cost - a.total_treatment_cost);
      } else if (sortDirection === "Date Created DESC") {
        sorted.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      }
  
      setSortedPatients(sorted);
    }
  }, [patients, sortDirection]); 


  const getMyPlan = async () => {
    try {
      const { data } = await axios.get("/subscription");
      setState(data.status);
      return data;
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  const getPracticeConsentClauseData = async () => {
    try {
      const { data } = await axios.get("/user");
      setUser(data[1].dentally_permission_level);
      console.log("ini",data[1].initial_login);
      setUserIni(data[1].initial_login);
      console.log("data",data);
      return data;
    
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const isAdmin = useQuery(["isAdmin"], async () => {
    const { data } = await axios.get("/user/is_admin");
    return data;
  });

  const getPatients = useQuery(
    ["patientsList", inputValue],
    async () => {
      const params = {
        page: 1,
        per_page: 5,
        query: inputValue,
      };
      const stringified = queryString.stringify(params);
      const { data } = await axios.get(`/patient?${stringified}`);
      return data;
    },
    {
      onSuccess: (data) => {
        const dropdownValues = data.data.map((patient) => ({
          label: `${patient.first_name} ${patient.last_name}`,
          value: patient.id,
        }));
        setPatientsList(dropdownValues);
      },
    }
  );

  const updateUploadStatus = useMutation(
    async (id: string) => {
      const { data } = await axios.put(
        `/consent_bundle/${id}/toggle_uploaded_to_dentally_patient_records`
      );
      return data;
    },
    {
      onSuccess: () => {
        treatmentDetails.refetch();
        toast.success("Record uploaded status updated successfully!");
      },
      onError: (error) => {
        toast.error("Error updating record uploaded status!");
      },
    }
  );

  const resendEmail = useMutation(
    async (idList: string[]) => {
      const { data } = await axios.post(
        `/consent_bundle/bulk_patient_request_to_sign_email`,
        { consent_bundle_ids: idList }
      );

      return data;
    },
    {
      onSuccess: () => {
        treatmentDetails.refetch();
        toast.success("Resend email initiated successfully!");
        setBulkActionSelectedList([]);
      },
      onError: (error) => {
        toast.error("Resend email failed!");
      },
    }
  );

  const resendEmailValidation = useMutation(
    async (idList: string[]) => {
      if (!idList || idList.length === 0) {
        toast.warning("Please select consent bundles to resend the emails!");
      } else {
        const { data } = await axios.post(
          `/consent_bundle/validate_bulk_patient_request_to_sign_email`,
          { consent_bundle_ids: idList }
        );
        return data;
      }
    },
    {
      onSuccess: (data) => {
        const hasNoEmails = data.length === 0;

        let confirmMessage;
        let buttonLabel;
        if (hasNoEmails) {
          confirmMessage = "Are you sure you want to resend the email to them?";
          buttonLabel = `Yes`;
        } else {
          const namesList = data
            .map(
              (element) =>
                `<strong>• ${element.first_name} ${element.last_name}</strong>`
            )
            .join("<br>");
          confirmMessage = `
          <div style="text-align: left;">
            <strong>The following patients do not have an email address:</strong><br>
            ${namesList}<br><br>
            Are you sure you want to resend the email to them?
          </div>`;
          buttonLabel = `Proceed Anyway`;
        }

        confirmAlert({
          title: "Confirm Resend Email",
          message: confirmMessage,
          buttons: [
            {
              label: buttonLabel,
              onClick: async () => {
                resendEmail.mutateAsync(bulkActionSelectedList);
              },
            },
            { label: "No", onClick: () => {} },
          ],
          // Enable HTML interpretation
          customUI: ({ onClose }) => (
            <div
              style={{
                border: "2px solid black",
                borderRadius: "16px",
                padding: "15px",
                backgroundColor: "white",
              }}
            >
              <h1>Confirm Resend Email</h1>
              <p dangerouslySetInnerHTML={{ __html: confirmMessage }}></p>
              <button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  resendEmail.mutateAsync(bulkActionSelectedList);
                  onClose();
                }}
              >
                {buttonLabel}
              </button>
              <button
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={onClose}
              >
                No
              </button>
            </div>
          ),
        });
      },
    }
  );

  const moveToPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const archiveConsentBundle = useMutation(
    (id) => {
      return axios.delete(`/consent_bundle/${id}/archive_consent_bundle`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["patients"] });
      },
    }
  );

  const clearFilters = () => {
    setPatient(undefined);
    setQuery("");
    setStatus("");
    setSortDirection(""); 
    setSort("Date Created DESC");
    setIsNonUploaded(false);
    setIsMysite(false);
    setIsTodayOnly(false);
    setDueIn24Hours(false);
    setSelectedClinician("");
  };
  

  const download = async (consentBundle: any, consentBundleId: string) => {
    try {
      setIsDownloading(true);
      let url;
      let data;
      if (consentBundleId) {
        setDownloadingConsentId(consentBundleId);
        const response = await axios.get(
          `/consent_bundle/${consentBundleId}/activity_summary`
        );
        data = response.data;
        url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_pdf`;
      } else {
        setDownloadingConsentId(consentBundle.id);
        url = `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundle.id}/generate_pdf`;
      }

      
      const response = await axios({
        url,
        method: "GET",
        responseType: "blob", 
      });

   
      const href = URL.createObjectURL(response.data);

      
      const link = document.createElement("a");
      link.href = href;

      if (consentBundleId) {
        link.setAttribute("download", getPdfName(data));
      } else {
        link.setAttribute("download", getPdfName(consentBundle));
      }

      document.body.appendChild(link);
      link.click();

    
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Download failed: ", error);
    } finally {
      setIsDownloading(false);
      setDownloadingConsentId(null);
    }
  };

  const handleClinicianChange = (event) => {
    const newValue = event.target.value;
    setSelectedClinician(newValue);
  };

  let message = "";
  if (state === "Inactive") {
    if (user === 1) {
      message =
        error?.response?.data?.detail === "User Not Under Subscription"
          ? "Your subscription plan is inactive. Please contact your administrator to purchase a new plan. Go to  <a href='/plans'>Plans Page</a>"
          : "Your subscription plan is expired. Please visit the <a href='/plans'>Plans Page</a> to purchase a new plan.";
    } else {
      message =
        "Your subscription plan is inactive. Please contact your administrator to purchase a new plan Go to  <a href='/plans'>Plans Page</a> .";
    }
  }

  const PreviousVersion = async (id) => {
    setIsDownloading(true);
    try {
      const response = await axios.get(
        `consent_bundle/${id}/get_consent_bundle_versions`
      );
      const fetchedData = response.data;
      const latestVersion =
        fetchedData && Array.isArray(fetchedData) && fetchedData.length > 0
          ? Math.max(...fetchedData.map((row) => row.version))
          : null;
      const updatedData = fetchedData.map((row) => ({
        ...row,
        isLatest: row.version === latestVersion,
      }));

      setData(updatedData);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching previous versions:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoTo = async (consentBundleId) => {
    download(null, consentBundleId);
    handleClose();
    try {
    } catch (error) {
      console.error("Error fetching activity summary:", error);
    }
  };

  const deleteConsentBundle = async (id) => {
    try {
      const response = await axios.delete(
        `/consent_bundle/${id}/delete_consent_bundle`
      );
      toast.success("Version rollbacked successfully!", {
        icon: <CheckCircleIcon style={{ color: "green" }} />,
      });
      await fetchTreatmentDetails();
    } catch (error) {
      console.error("Error deleting consent bundle:", error);
    }
  };

  useEffect(() => {
    fetchTreatmentDetails();
  }, []);

  const fetchTreatmentDetails = async () => {
    try {
      const params = {
        page,
        per_page: 25,
        patient,
        query,
        sort_by: sort,
        consent_status: status,
        non_uploaded_only: isNonUploaded,
        my_site:isMysite,
        today_only: isTodayOnly,
        due_tomorrow_only: duein24Hours,
        clinician: selectedClinician,
      };

      const stringified = queryString.stringify(params);

      const { data } = await axios.get(`/consent_bundle?${stringified}`);

      const token = axios.defaults.headers.common.Authorization;
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      const practiceURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/practices`;
      const practiceResponse = await axios.get(practiceURL, config);
      const clinicianURL = `${process.env.REACT_APP_BASE_URL}users/dropdown/clinicians?practice=${practiceResponse.data[0].value}`;
      const clinicianResponse = await axios.get(clinicianURL, config);
      setClinicianList(clinicianResponse.data);
      const plans = data.data.map((patient) => ({
        ...patient,
        label: `${patient.patient?.first_name} ${patient.patient?.last_name}`,
      }));
      setPatients(plans);
      return data;
    } catch (error) {
      console.error("Error fetching treatment details:", error);
    }
  };

  const handlePrivacyClick = async () => {
    try {
      const response = await axios.get(`document_agreement/3`);
      setPrivacyContent(response.data.document);
      setShowPrivacyModal(true);
      setShowAcceptButton(false);
    } catch (error) {
      setPrivacyContent("Failed to load Privacy Policy.");
      setShowPrivacyModal(true);
      setShowAcceptButton(false);
    }
  };

  const handleSAASClick = async () => {
    try {
      const response = await axios.get("document_agreement/2");
      setSAASContent(response.data.document);
      setShowSAASModal(true);
      setShowAcceptButton(false);
    } catch (error) {
      setSAASContent("Failed to load Privacy Policy.");
      setShowSAASModal(true);
      setShowAcceptButton(false);
    }
  };

  const handleEulaClick = async () => {
    try {
      const response = await axios.get("document_agreement/1");
      setEulaContent(response.data.document);
      setShoweulaModal(true);
      setShowAcceptButton(false);
    } catch (error) {
      setEulaContent("Failed to load Privacy Policy.");
      setShoweulaModal(true);
      setShowAcceptButton(false);
    }
  };

  useEffect(() => {
    const fetchDocumentAgreement = async () => {
      try {
        const { data } = await axios.get("/user");
        const userData = data[1].medicube_user_id;

        const responseEula = await axios.get("document_agreement", {
          params: {
            user_type: "system_user",
            user_id: userData,
            document_id: 1,
          },
        });

        if (responseEula.data) {
          setShowAcceptButton(true);
          try {
            const response = await axios.get("document_agreement/1");
            setEulaContent(response.data.document);
            setShoweulaModal(true);
          } catch (error) {
            setEulaContent("Failed to load Privacy Policy.");
            setShoweulaModal(true);
          }
        }

        const responseSAAS = await axios.get("document_agreement", {
          params: {
            user_type: "system_user",
            user_id: userData,
            document_id: 2,
          },
        });

        if (responseSAAS.data) {
          setShowAcceptButton(true);
          try {
            const response = await axios.get("document_agreement/2");
            setSAASContent(response.data.document);
            setShowSAASModal(true);
          } catch (error) {
            setSAASContent("Failed to load Privacy Policy.");
            setShowSAASModal(true);
          }
        }

        const responsePrivacy = await axios.get("document_agreement", {
          params: {
            user_type: "system_user",
            user_id: userData,
            document_id: 3,
          },
        });

        if (responsePrivacy.data) {
          setShowAcceptButton(true);
          try {
            const response = await axios.get(`document_agreement/3`);
            setPrivacyContent(response.data.document);
            setShowPrivacyModal(true);
          } catch (error) {
            setPrivacyContent("Failed to load Privacy Policy.");
            setShowPrivacyModal(true);
          }
        }

        console.log("API Response:Eula", responseEula.data);
        console.log("API Response:SAAS", responseSAAS.data);
        console.log("API Response:Privacy", responsePrivacy.data);
      } catch (error) {
        console.error("Error fetching document agreement:", error);
      }
    };

    fetchDocumentAgreement();
  }, []);

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const [showBannerTutorial, setShowBannerTutorial] = useState(false)
  useEffect(() => {
    const initialLoginState = localStorage.getItem('initial_user_login');
    if (initialLoginState === 'true') {
      setShowBannerTutorial(true);
      localStorage.setItem('initial_user_login', 'false');
    }
  }, []);

  const filtersActive =
    patient ||
    query ||
    status ||
    sort !== "Date Created DESC" ||
    sortDirection || 
    isNonUploaded ||
    isTodayOnly ||
    duein24Hours ||
    selectedClinician ||
    isMysite;
  
  return (
    <div className={styles.dashboard}>
    <Paper
      elevation={2} 
      sx={{
        padding: "16px",
        borderRadius: "12px",
        border: "1px solid #ccc", 
        backgroundColor: "#fff", 
      }}
    >
      <div
        className={styles.filters}
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "16px",
          marginBottom: "5px",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px", flex: "1" }}>
          {/* Search Patients */}
          <Autocomplete
            value={null}
            disableClearable
            onChange={(event: any, newValue: any) => setPatient(newValue.value)}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            inputValue={inputValue}
            options={patientsList}
            sx={{
              width: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiFilledInput-root": {
                borderRadius: "12px",
                "&:before, &:after": {
                  borderBottom: "none", 
                },
              },
              "& .MuiInputBase-root": {
                borderRadius: "12px",
                "&:before, &:after": {
                  borderBottom: "none",
                },
              },
            }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                value={patient || ""}
                label="Search patients"
                variant="filled"
                sx={{
                  borderRadius: "12px",
                }}
              />
            )}
          />


          {/* Filter by Clinician */}
          <FormControl variant="filled" sx={{ minWidth: 200, borderRadius: "19px" }}>
          <InputLabel>Filter by Clinician</InputLabel>
          <Select
            value={selectedClinician}
            onChange={handleClinicianChange}
            displayEmpty
            sx={{
              borderRadius: "12px", 
              "& .MuiSelect-select": {
                borderRadius: "12px", 
              },
              "& .MuiFilledInput-root": {
                borderRadius: "12px", 
                "&:before, &:after": {
                  border: "none", 
                },
              },
              "& .MuiInput-underline": {
                display: "none",
              },
            }}
          >
            <MenuItem value="" disabled></MenuItem>
            {clinicianList.map((clinician: any) => (
              <MenuItem key={clinician.value} value={clinician.value}>
                {clinician.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

          {/* Status Filter */}
          <FormControl variant="filled" sx={{ minWidth: 200, borderRadius: "19px" }}>
            <InputLabel>Status</InputLabel>
            <Select value={status} onChange={(e) => setStatus(e.target.value)} sx={{
                borderRadius: "12px", // Round edges for the select dropdown
                "& .MuiFilledInput-root": {
                  borderRadius: "12px", // Round edges for input field
                  "&:before, &:after": {
                    border: "none", // Removes the underline before and after the input field
                  },
                },
                "& .MuiInput-underline": {
                  display: "none", // Removes underline specifically
                },
              }}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Draft">Draft</MenuItem>
              <MenuItem value="Sent For Signing">Sent For Signing</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>
              <MenuItem value="Expired">Expired</MenuItem>
            </Select>
          </FormControl>

          {/* Sorting Dropdown */}
          <FormControl variant="filled" sx={{ minWidth: 200, borderRadius: "19px" }}>
        <InputLabel>Sort by Value</InputLabel>
        <Select
          value={sortDirection}
          onChange={(e) => handleSort(e.target.value)}
          displayEmpty
          sx={{
            borderRadius: "12px",
            "& .MuiFilledInput-root": {
              borderRadius: "12px", 
              "&:before, &:after": {
                border: "none", 
              },
            },
            "& .MuiInput-underline": {
              display: "none", 
            },
          }}
        >
          
          <MenuItem value="asc">Low to High</MenuItem>
          <MenuItem value="desc">High to Low</MenuItem>
        </Select>
      </FormControl>



          {/* Toggle Buttons for Filters */}
          <FormControlLabel
            control={<Switch checked={isTodayOnly} onChange={() => setIsTodayOnly(!isTodayOnly)} />}
            label="Due Today"
          />
          <FormControlLabel
            control={<Switch checked={duein24Hours} onChange={() => setDueIn24Hours(!duein24Hours)} />}
            label="Due in 24 Hours"
          />
          <FormControlLabel
            control={<Switch checked={isNonUploaded} onChange={() => setIsNonUploaded(!isNonUploaded)} />}
            label="Ready For Upload"
          />
           <FormControlLabel
            control={<Switch checked={isMysite} onChange={() => setIsMysite(!isMysite)} />}
            label="My site"
          />

          {/* Clear Filters Button */}
          {filtersActive && (
        <Button
          type="button"
          title="Clear Filters"
          onClick={clearFilters}
          variant="text"
          startIcon={<Clear />}
          sx={{
            backgroundColor: "transparent", 
            color: "#FF9800", 
            border: "none", 
            "&:hover": {
              backgroundColor: "rgba(255, 152, 0, 0.1)", 
            },
          }}
        >
          Clear filters
        </Button>
      )}

        </div>

       
        <Button
          type="button"
          variant="contained"
          className={styles.createNew}
          onClick={() => setIsOpenCreateConsentModal(true)}
          sx={{ marginLeft: "auto" }}
        >
          + New Consent Request
        </Button>

       
        {archiveConsentBundle.isLoading && (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative' }}>
    <Lottie animationData={videoLoadingAnimation} style={{ width: 350, height: 350 }} />
    <img
      src="/logo.png"
      alt="Overlay image"
      style={{
        position: 'absolute',
        top: '45%',  
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        height: 70, 
      }}
    />
  </div>
)}
      </div>
    </Paper>


      {state === "Inactive" && (
        <div className={styles.inactiveBanner}>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      )}
      {treatmentDetails.isLoading ? (
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative' }}>
       <Lottie animationData={videoLoadingAnimation} style={{ width: 350, height: 350 }} />
       <img
         src="/logo.png"
         alt="Overlay image"
         style={{
           position: 'absolute',
           top: '45%', 
           left: '50%',
           transform: 'translate(-50%, -50%)',
           width: 200, 
           height: 70, 
         }}
       />
     </div>
      ) : (
        
        <TableContainer component={Paper} sx={{ marginTop: "16px" }}>


        <>
            {showBannerTutorial && (
              <div className={styles.banner}>
                <img
                  src="/workflowdash.png"
                  alt="Step-by-step instructions"
                  style={{
                    width: "830px",
                    height: "auto",
                  }}
                />
              </div>
            )}
          </>
    
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
              <TableRow>
                <TableCell
                  size="small"
                  padding="none"
                  className={styles.smallCell}
                >
                  <Select
                    value={null}
                    label="Bulk Actions"
                    variant="filled"
                    onChange={(e) => {
                      if (e.target.value === `resend_Email`) {
                        resendEmailValidation.mutateAsync(bulkActionSelectedList);
                      }
                    }}
                  >
                    <MenuItem value="resend_Email">Resend Email</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>Email Status</TableCell>
                <TableCell>ID</TableCell>
                
                {/* <TableCell>clinician name</TableCell> */}
                <TableCell>Patient</TableCell>
                <TableCell>Date Of Birth</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>
  <div
    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    onClick={handleHeaderSort}
  >
    Value
    {sortDirection === "asc" ? " ↓ " : sortDirection === "desc" ? " ↑" : ""}
  </div>
</TableCell>

                <TableCell>Record Uploaded</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>


            <TableBody>
            {sortedPatients.map((patient, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={cx({ [styles.archived]: patient.is_archived })}
                onClick={() => {
                  if (patient.status === "Draft") {
                    navigate(`/${patient.id}`);
                  } else {
                    navigate(`/${patient.id}/summary`);
                  }
                }}
              >
                  <TableCell
                    size="small"
                    padding="none"
                    className={styles.smallCell}
                  >
                    {["Awaiting Completion", "Sent For Signing"].includes(
                      patient.status
                    ) && (
                      <Button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (bulkActionSelectedList.includes(patient.id)) {
                            setBulkActionSelectedList(
                              bulkActionSelectedList.filter(
                                (id) => id !== patient.id
                              )
                            );
                          } else {
                            setBulkActionSelectedList([
                              ...bulkActionSelectedList,
                              patient.id,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          checked={bulkActionSelectedList.includes(patient.id)}
                        />
                      </Button>
                    )}
                  </TableCell>

                  <TableCell>
  <Tooltip
    title={
      patient.status === "Draft"
        ? "Email Available but Not Sent (Pending action)"
        : patient.patient?.email
        ? "Email Sent Successfully (Confirmed delivery)"
        : "Email Unavailable (No email found or invalid)"
    }
  >
    {patient.status === "Draft" ? (
      <HourglassEmptyIcon sx={{ color: "grey" }} />
    ) : patient.patient?.email ? (
      <MarkunreadIcon sx={{ color: "green" }} />
    ) : (
      <UnsubscribeIcon sx={{ color: "red" }} />
    )}
  </Tooltip>
</TableCell>


                  <TableCell>
                    {patient.code.includes("-")
                      ? `${patient.treatment_plan_id}-${
                          patient.code.split("-")[1]
                        }`
                      : patient.treatment_plan_id}
                  </TableCell>


                  {/* <TableCell>
                    {`${patient?.clinician_name}`}
                  </TableCell> */}

                  <TableCell>
                    {`${patient.patient?.first_name} ${patient.patient?.last_name}`}
                  </TableCell>

                  <TableCell>
                    {patient.patient?.date_of_birth &&
                      formatDate(patient.patient.date_of_birth)}
                  </TableCell>

                  <TableCell>
                    <div className={styles.statusCell}>
                      <div
                        className={cx(styles.status, {
                          [styles.draft]: patient.status === "Draft",
                          [styles.awaitingCompletion]:
                            patient.status === "Awaiting Completion",
                          [styles.completed]: patient.status === "Completed",
                        })}
                      />
                      {patient.status === "Draft" && patient.version_no > 1 ? (
                        <span className={styles.draft}>
                          Draft (In Revision)
                        </span>
                      ) : (
                        <span className={styles.draft}>{patient.status}</span>
                      )}
                    </div>
                  </TableCell>


                  <TableCell>{patient.total_treatment_cost}</TableCell>

                  <TableCell>
                    {patient.status === "Completed" && (
                      <>
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            updateUploadStatus.mutateAsync(patient.id);
                          }}
                        >
                          <Checkbox
                            checked={
                              patient.uploaded_to_dentally_patient_record
                            }
                          />
                        </Button>
                      </>
                    )}
                  </TableCell>

                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setConsentLogsModalId(patient.id);
                        }}
                        variant="outlined"
                        startIcon={<QuickviewIcon />}
                      >
                        Quick view
                      </Button>
                      <Button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          download(patient);
                        }}
                        variant="outlined"
                        disabled={downloadingConsentId === patient.id}
                        startIcon={<DownloadIcon />}
                      >
                        {downloadingConsentId === patient.id
                          ? "Downloading..."
                          : "Download"}
                      </Button>

                      {!patient.is_archived && !(patient.version_no > 1 && patient.status === "Draft") ? ( 
                      
                        <Button
                          type="button"
                          disabled={archiveConsentBundle.isLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            confirmAlert({
                              title: "Confirm to archive",
                              message:
                                "Are you sure you want to archive this consent?",
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: async () => {
                                    archiveConsentBundle.mutate(patient.id);
                                  },
                                },
                                {
                                  label: "No",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }}
                          variant="outlined"
                          startIcon={<ArchiveIcon />}
                        >
                          Archive
                        </Button>
                      ) : (
                        <>
                        {!patient.is_archived && (
                          <Button
                            type="button"
                            disabled={deleteConsentBundle.isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              confirmAlert({
                                title: "Warning",
                                message:
                                  "Deleting this version will remove it from the final consent report, and no record will be retained. Do you want to proceed?",
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: async () => {
                                      deleteConsentBundle(patient.id);
                                    },
                                  },
                                  {
                                    label: "Cancel",
                                    onClick: () => {},
                                  },
                                ],
                              });
                            }}
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                          >
                            Revert
                          </Button>
                        )}
                      </>
                      )}

                      {patient.version_no > 1 && (
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            PreviousVersion(patient.id);
                          }}
                          variant="outlined"
                          startIcon={<HistoryIcon />}
                        >
                          versions
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Versions </DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Consent Bundle ID</TableCell>
                      <TableCell>Code</TableCell>
                      <TableCell>Version</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && Array.isArray(data) && data.length > 0 ? (
                      data.map((row) => (
                        <TableRow key={row.consent_bundle_id}>
                          <TableCell>{row.consent_bundle_id}</TableCell>
                          <TableCell>{row.code}</TableCell>
                          <TableCell>{row.version}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                backgroundColor: row.isLatest
                                  ? "green"
                                  : "Crimson",
                              }}
                              onClick={() => handleGoTo(row.consent_bundle_id)}
                            >
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          No previous versions found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      )}
      {true && (
        <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(treatmentDetails?.data?.meta?.total / 25) || 1}
            shape="rounded"
            onChange={moveToPage}
          />
        </div>
      )}
      <ConsentLogs
        isOpen={!!consentLogsModalId}
        closeModal={() => setConsentLogsModalId("")}
        consentId={consentLogsModalId}
      />
      {/* <CreateConsentForm isOpen={isOpenCreateConsentModal} setIsOpen={setIsOpenCreateConsentModal} /> */}
      <CreateConsentForm
        isOpen={isOpenCreateConsentModal}
        setIsOpen={setIsOpenCreateConsentModal}
        user={user}
      />
      <br />

      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <img src="/logo.png" alt="Company Logo" className={styles.logo} />
          <div className={styles.divider}></div>
          <div>
            <p>&copy; 2024 Medicube. All rights reserved.</p>
            <p>
              <a onClick={handlePrivacyClick} className={styles.link}>
                Privacy Policy
              </a>{" "}
              |
              <a onClick={handleSAASClick} className={styles.link}>
                SAAS
              </a>{" "}
              |
              <a onClick={handleEulaClick} className={styles.link}>
                EULA
              </a>
            </p>
          </div>
        </div>
      </footer>

      <PrivacyPolicyModal
        open={showPrivacyModal}
        onClose={() => setShowPrivacyModal(false)}
        content={privacyContent}
        showAcceptButton={showAcceptButton}
        userId={user}
      />

      <SaasModal
        open={showSAASModal}
        onClose={() => setShowSAASModal(false)}
        content={SAASContent}
        showAcceptButton={showAcceptButton}
        userId={user}
      />

      <EulaModal
        open={showEulaModal}
        onClose={() => setShoweulaModal(false)}
        content={eulaContent}
        showAcceptButton={showAcceptButton}
        userId={user}
      />
    </div>
  );
}
