import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from '@mui/material';
import MainCard from '../../components/MainCard';
import Sidebar from "../../menu-items/Sidebar";
import axios from 'axios';

const UserLogins = () => {
  const [data, setData] = useState([]); // Data to display in the table
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(20); // Number of rows per page

  useEffect(() => {
    const fetchData = async () => {
      const token = axios.defaults.headers.common.Authorization;
      const url = `${process.env.REACT_APP_BASE_URL}admin/reports/user_logins`;

      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      try {
        const response = await axios.get(url, config);
        
        setData(response.data); // Save the response data to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

 

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  // Function to download table as SVG
  const downloadAsCSV = () => {
    const csvRows = [];
    const headers = ['#', 'Event ID', 'Event', 'Description', 'Practice', 'User ID', 'Role', 'Timestamp'];
    csvRows.push(headers.join(',')); // Adding the header row

    data.forEach((row, index) => {
      const rowData = [
        page * rowsPerPage + index + 1,
        row['event id'],
        row.event,
        row.description,
        row.practice,
        row['user id'],
        row.role,
        row.timestamp,
      ];
      csvRows.push(rowData.join(','));
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'user_logins.csv';
    link.click();
  };

  // Get the records to display on the current page
  const displayedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ border: '1px solid #ccc', padding: 5, height: '100vh' }}>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={12} md={9} lg={10}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',
              }}
            >
              User Login Data
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: 2 }}
              onClick={downloadAsCSV}
            >
              Download as CSV
            </Button>
           
          </Box>

          <MainCard title="">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: '15px',
                boxShadow: 3,
                overflow: 'hidden',
                marginTop: 2,
              }}
            >
              <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                    <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Event ID</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Event</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Practice</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>User ID</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Role</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    displayedData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                            cursor: 'pointer',
                          },
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row['event id']}</TableCell>
                        <TableCell>{row.event}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.practice}</TableCell>
                        <TableCell>{row['user id']}</TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell>{row.timestamp}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserLogins;
