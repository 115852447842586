import React, { useState, useEffect, useRef } from "react";
import { Facebook, LinkedIn, Twitter, Instagram, Language, Email, WhatsApp,Close ,Info  } from "@mui/icons-material";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton ,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import imageCompression from "browser-image-compression";
import styles from "./Templates.module.scss";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import InfoIcon from '@mui/icons-material/Info';

export default function TemplatesComponent({ closeModal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedSite, setSelectedSite] = useState(null);
  const [applyForAllPractices, setApplyForAllPractices] = useState(false);
  const [selectedSitecheck, setSelectedSitecheck] = useState(true);
  const [practiceData, setPracticeData] = useState({});
  const [practiceSites, setPracticeSites] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const [whatsapp, setWhatapp] = useState("");
  const [website, setwebsite] = useState("");
  const [email, setemail] = useState("");
  const [instagram, setinstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [facebook, setfacebook] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalReview, setOpenModalReview] = useState(false);

  const cropperRef = useRef(null);

  const handleOpenModalReview = () => setOpenModalReview(true);
  const handleCloseModalReview = () => setOpenModalReview(false);

  const fetchPracticeSites = async () => {
    try {
      const response = await axios.get(`/site/`);
      setPracticeSites(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching practice sites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPracticeSites();
  }, []);

  const handleChange = (e) => {
    const Id = e.target.value;
    setSelectedSite(Id);
    const selectedSiteObject = practiceSites.find((site) => site.id === Id);
    if (selectedSiteObject) {
      setSelectedSitecheck(false);
      getNameAndLogo(Id);
    } else {
      setSelectedSitecheck(true);
      setPracticeName("");
    }
  };

  const [practiceDataLink, setPracticeDataLink] = useState({
    website: "",
    email: "",
    whatsapp: "",
    instagram: "",
    tiktok: "",
    facebook: "",
    linkedin: "",
    googlemap:"",
    googlereviews:""
  });
  
  

  const handleChangeBrand = (e) => {
    const { name, value } = e.target;
    setPracticeDataLink((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDrop = async (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setUploadedImageUrl(imageUrl);
    setIsCropping(true); 
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const cropperInstance = cropperRef.current?.cropper; 
      if (cropperInstance) {
        const croppedCanvas = cropperInstance.getCroppedCanvas({
          width: 250, 
          height: 250, 
        });

        const croppedData = croppedCanvas.toDataURL();
        setCroppedImage(croppedData);
        setIsCropping(false); 
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const mutation = useMutation(
    async ({ id, formData }) => {
      const response = await axios.post(
        `/site/${id}/save_site_consent_clause_data`,
        formData
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("Practice name and logo uploaded successfully.");
        setUploading(false);
        closeModal();
      },
    }
  );

  const getNameAndLogo = async (Id) => {
    try {
      const response = await axios.get(`/site/${Id}/get_site_consent_clause_data`);
      console.log(response);

      const googleReviewsUrl = response.data.links.googlereviews || "";
      const urlParams = new URLSearchParams(new URL(googleReviewsUrl).search);
      
      const placeid = urlParams.get("placeid") || "";  
      const apikey = urlParams.get("key") || ""; 

      setPracticeData(response.data);
      setPracticeName(response.data.display_name || "");
      
      setPracticeDataLink({
        website: response.data.links.website || "",
        email: response.data.links.email || "",
        whatsapp: response.data.links.whatsapp || "",
        instagram: response.data.links.instagram || "",
        tiktok: response.data.links.tiktok || "",
        facebook: response.data.links.facebook || "",
        linkedin: response.data.links.linkedin || "",
        googlemap: response.data.links.googlemap || "",
        googlereviews: googleReviewsUrl,   
        placeid: placeid,                
        apikey: apikey,    
      });
      
    } catch (error) {
      console.error("Error fetching practice data:", error);
    }
  };
  

  const handleSubmit = async () => {
    if (applyForAllPractices) {
      setIsConfirmationOpen(true);
    } else {
      await uploadData();
    }
  };

  const autoCropImage = async () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedData = croppedCanvas.toDataURL("image/png");
        setCroppedImage(croppedData);
        return croppedData; 
      }
    }
    return null;
  };

  const [errors, setErrors] = useState({});
  const validateLinks = (links) => {
    const newErrors = {};
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[1-9]\d{1,14}$/;
    const tiktokPattern = /^https?:\/\/(www\.)?tiktok\.com\/@[\w.-]+(\?.*)?$/;
    const googleMapsPattern = /https?:\/\/(www\.)?google\.[a-z]+\/maps\/.*|https:\/\/maps.app\.goo\.gl\/[A-Za-z0-9]+/;
    const facebookPattern = /https?:\/\/(www\.)?(facebook\.com|fb\.com)\/[A-Za-z0-9_.-]+(\?.*)?/;

    if (links.website && !urlPattern.test(links.website)) {
      newErrors.website = "Invalid website URL.";
    }
    if (links.email && !emailPattern.test(links.email)) {
      newErrors.email = "Invalid email address.";
    }
    if (links.whatsapp && !phonePattern.test(links.whatsapp)) {
      newErrors.whatsapp = "Invalid WhatsApp number. Add country code. (exmplae +44)";
    }
    if (links.instagram && !urlPattern.test(links.instagram)) {
      newErrors.instagram = "Invalid Instagram URL.";
    }
    if (links.tiktok && !tiktokPattern.test(links.tiktok)) {
      newErrors.tiktok = "Invalid TikTok URL.";
    }
    if (links.facebook && !facebookPattern.test(links.facebook)) {
      newErrors.facebook = "Invalid Facebook URL.";
    }
    if (links.linkedin && !urlPattern.test(links.linkedin)) {
      newErrors.linkedin = "Invalid LinkedIn URL.";
    }
    if (links.googlemap && !googleMapsPattern.test(links.googlemap)) {
      newErrors.googlemap = (
        <>
          Invalid Google Maps link. (Example: <strong>https://maps.app.goo.gl/***********</strong>){" "}
          <Button
            size="small"
            color="primary"
            onClick={() => setOpenModal(true)}
            startIcon={<Info />}
          >
            Learn more
          </Button>
        </>
      );
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateLinks({ [name]: value });
  };


  const uploadData = async () => {
    if (!practiceName) {
      toast.error("Please upload an image and enter the practice name.");
      return;
    }

    const links = {
      website: practiceDataLink.website || "",
      email: practiceDataLink.email || "",
      whatsapp: practiceDataLink.whatsapp || "",
      instagram: practiceDataLink.instagram || "",
      tiktok: practiceDataLink.tiktok || "",
      facebook: practiceDataLink.facebook || "",
      linkedin: practiceDataLink.linkedin || "",
      googlemap: practiceDataLink.googlemap || "",
      googlereviews: practiceDataLink.googlereviews || "",
    };
  
    if (!validateLinks(links)) {
      return; // Stop execution if validation fails
    }


    setUploading(true);
    setUploadError("");
    let base64Image = null;

    try {
      if (croppedImage) {
        console.log(croppedImage);
        base64Image = croppedImage; // ise cropped image
      }else{
        base64Image = await autoCropImage();
      }

      const links = {
        website: practiceDataLink.website || "",
        email: practiceDataLink.email || "",
        whatsapp: practiceDataLink.whatsapp || "",
        instagram: practiceDataLink.instagram || "",
        tiktok: practiceDataLink.tiktok|| "",
        facebook: practiceDataLink.facebook || "",
        linkedin: practiceDataLink.linkedin || "",
        googlemap: practiceDataLink.googlemap || "",
        googlereviews: practiceDataLink.googlereviews || "",
      };

    console.log(links)

      if (applyForAllPractices) {
        for (const site of practiceSites) {
          const response = await axios.get(
            `/site/${site.id}/get_site_consent_clause_data`
          );
          const formData = {
            logo: base64Image,
            display_name: response.data.display_name,
            links: links, 
          };

          await mutation.mutateAsync({ id: site.id, formData });
        }
      }

      const formData = {
        logo: base64Image,
        display_name: practiceName,
        links: links,
      };

      await mutation.mutateAsync({ id: selectedSite, formData });
    } catch (error) {
      console.error("Error uploading data:", error);
      setUploadError("Error uploading data. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleDrop,
    maxSize: 1000000, // 1MB 
  });

  return (
    <div>
      <div className={styles.modal}>
      <Box
          sx={{
            maxHeight: "80vh", 
            overflowY: "auto",
            padding: 3,
          }}
        >
              <Box mb={4}>
          <h2 className={styles.header}>Upload Practice Name and Logo</h2>
        </Box>

        <h3 className={styles.header}>Select a Practice</h3>
        <Select
          value={selectedSite}
          onChange={handleChange}
          fullWidth
          variant="filled"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {practiceSites.map((site) => (
            <MenuItem key={site.id} value={site.id}>
              {site.name}
            </MenuItem>
          ))}
        </Select>

        {!selectedSitecheck && (
          <>
            <Box mt={2} className={styles.content}>
              <h3 className={styles.header}>
                Existing Practice Display Name & Logo
              </h3>
              <div className={styles.logoAndName}>
                {practiceData.logo ? (
                  <img
                    src={practiceData.logo}
                    alt="Logo"
                    className={styles.logo}
                  />
                ) : (
                  <div className={styles.noLogoText}>No Logo</div>
                )}
                <h2>{practiceData.display_name}</h2>
              </div>
            </Box>

            <h3 className={styles.header}>
              Display Name for Selected Practice
            </h3>
            <TextField
              label="Practice Name"
              name="practiceName"
              required
              type="text"
              variant="filled"
              fullWidth
              className="mg-b-1"
              value={practiceName}
              onChange={(e) => setPracticeName(e.target.value)}
            />

            <h3 className={styles.header}>Logo Upload</h3>
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} />
                {uploadedImageUrl && !isCropping ? (
                  <img
                    src={uploadedImageUrl}
                    alt="Preview"
                    className={styles.previewImage}
                  />
                ) : (
                  <>
                    <img
                      src="../../Upload.png"
                      alt="Drop Here"
                      className={styles.dropIcon}
                    />
                    <p className={styles.dropzoneText}>
                      Drag and drop an image here
                    </p>
                  </>
                )}
              </div>

            {isCropping && (
              <>
                <Box mt={2}>
                  <Cropper
                    ref={cropperRef}
                    src={uploadedImageUrl}
                    style={{ height: 250, width: "100%" }}
                    aspectRatio={1} 
                    guides={false}
                    minWidth={250} 
                    minHeight={250} 
                    viewMode={1} 
                  />
                  <Button
                  variant="contained"
                  onClick={handleCrop}
                  style={{ marginTop: "16px" }}
                >
                  Crop Image
                </Button>
                </Box>
                
              </>
            )}

            {croppedImage && (
              <Box mt={2} style={{ textAlign: "center" }}>
                <Typography variant="h6">Cropped Image Preview</Typography>
                <img
                  src={croppedImage}
                  alt="Cropped Preview"
                  className={styles.previewImage}
                />
              </Box>
            )}

    <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">How to Get a Google Maps Link</Typography>
            <IconButton onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <Typography sx={{ mt: 2 }}>
            1️⃣ Open Google Maps on your phone or computer. <br />
            2️⃣ Search for your location or place. <br />
            3️⃣ Tap on the location, then click "Share". <br />
            4️⃣ Copy the short link (example: https://maps.app.goo.gl/***********). <br />
            5️⃣ Paste it into the field above.
          </Typography>
        </Box>
      </Modal>      

    <Box
      mt={3}
      mb={3}
      sx={{
        border: "2px solid black",
        borderRadius: "8px",
        padding: "16px",
        marginBottom:"100px"
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>Branding</h3>

      {[
        { label: "Website", name: "website", icon: <Language sx={{ color: "#4CAF50" }} /> },
        { label: "Email", name: "email", icon: <Email sx={{ color: "#D32F2F" }} /> },
        { label: "WhatsApp", name: "whatsapp", icon: <WhatsApp sx={{ color: "#25D366" }} /> },
        { label: "Instagram", name: "instagram", icon: <Instagram sx={{ color: "#E1306C" }} /> },
        {
          label: "TikTok",
          name: "tiktok",
          icon: <img src="/tiktok.png" alt="TikTok" style={{ width: "24px", height: "24px" }} />,
        },
        { label: "Facebook", name: "facebook", icon: <Facebook sx={{ color: "#1877F2" }} /> },
        { label: "LinkedIn", name: "linkedin", icon: <LinkedIn sx={{ color: "#0077B5" }} /> },
        {
          label: "Google Maps Link",
          name: "googlemap",
          icon: <img src="/map.png" alt="Google Maps" style={{ width: "24px", height: "24px" }} />,
        },
        {
          label: "Google Review",
          name: "googlereviews",
          icon: <img src="gr.jpeg" alt="Google Review" style={{ width: "24px", height: "24px" }} />,
        },
      ].map(({ label, name, icon }) => (
        <TextField
          key={name}
          label={label}
          name={name}
          fullWidth
          variant="outlined"
          className="roundedTextField"
          value={practiceDataLink[name]}
          onChange={handleChangeBrand}
          onBlur={handleBlur}
          error={!!errors[name]}
          helperText={errors[name] || ""}
          InputProps={{
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
          }}
          sx={{ mb: 2 }}
        />
      ))}

     



    </Box>

        <FormControlLabel
              control={
                <Checkbox
                  checked={applyForAllPractices}
                  onChange={(e) => setApplyForAllPractices(e.target.checked)}
                  name="applyForAllPractices"
                />
              }
              label="Apply to all practices"
            />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={uploading}
            >
              {uploading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
          </>
        )}

        <Dialog
          open={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to apply this update to all practices?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmationOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={uploadData} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        </Box>
      </div>
    </div>
  );
}
