import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './global.scss';
import Header from './components/Header.tsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import axios from 'axios';
import React, { useEffect ,useState} from 'react';

import ConsentDetails from './components/ConsentDetails';
import Dashboard from './components/Dashboard';
import Landing from './components/Landing';
import Templates from './components/Templates';
import Thankyou from './components/Thankyou';
import Summary from './components/Summary';
import Login from './components/Login';
import LoginRedirect from './components/LoginRedirect';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter, Routes, Route, Navigate   } from "react-router-dom";
import Cookies from 'js-cookie';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import Plans from './components/Plans.tsx';
import CorporateForm from './components/ContactSales/CorporateForm.tsx';
import PurchaseDetailsForm from './components/PurchaseDetails/PurchaseDetailsForm.tsx';
import Error from './components/Error.tsx';
import PurchaseConfirmation from './components/PurchaseConfirmation.tsx';
import MyPlan from './components/MyPlan/MyPlan.tsx';
import Expired from './components/Expired.tsx';
import Tutorials from './components/Tutorials/Tutorials.tsx';
import VideoManager from './components/VideoManager/VideoManager.tsx';
import LogoPage from './components/LogoPage.tsx';
import Capture from './components/CameraCpature/Camera.tsx';
import TemplatesV2 from './components/Template/TemplatesV2.tsx';

//admin
import HomepageInternal from './components/Admin/pages/homepage/index.js';
import UserFeedback from './components/Admin/pages/user-feedback/index.js';
import Waitlist from './components/Admin/pages/waitlist/index.js';
import UserLogin from './components/Admin/pages/user-logins/index.js';
import UserList from './components/Admin/pages/user-list/index.js';
import CompletedConsentBundles from './components/Admin/pages/completed-consent-bundles/index.js';
import CompliancePage from './components/Admin/pages/compliance-management/index.js';
import ExistingUsersPage from './components/Admin/pages/existing-users/index.js';
import HelpPage from './components/Admin/pages/help&support/index.js';
import NewUsersPage from './components/Admin/pages/new-users/index.js';
import PatientPage from './components/Admin/pages/patient-engagement/index.js';

//user
import HomepageInternalUser from './components/User/pages/user-homepage/index.js';
import CompliancePageUser from './components/User/pages/user-compliance-management/index.js';
import ComplianceLeaderboardUser from './components/User/pages/user-compliance-leaderboard/index.js';
import HelpPageUser from './components/User/pages/user-help&support/index.js';
import PatientPageUser from './components/User/pages/user-patient-engagement/index.js';

import PasswordPrompt from './components/PasswordPrompt';

// Create a client
const queryClient = new QueryClient();
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common.Authorization =  `Bearer ${Cookies.get('token')}`;
//axios.defaults.headers.common.Authorization =  `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDkiLCJleHAiOjE3NDM0MjE0MjN9.Avu-IRT1Ep0397S0lvp-IJtS5wyEBKlgySehKaP2sJ0`;
// axios.defaults.withCredentials = true;


axios.interceptors.response.use(
  response => {
      return response;
  },
  error => { 
      let status = 'unknown';
      if (error.response) {
          status = error.response.status;
      }
      switch (status) {
          // Not authorized or accessToken expired.
          case 401: {
              window.location.href='/login';
              Cookies.set('token', '');
              break;
          }
          case 402: {
            window.location.href='/expired';
            break;
          }
          case 403: {
            window.location.href='/login';
            Cookies.set('token', '');
            break;
          }
          default: {
              return Promise.reject(error);
          }
      }
  }
);

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(true);

  const handlePasswordSubmit = () => {
    setIsAuthenticated(true); 
    setShowPasswordPrompt(false); 
  };

  const handlePasswordCancel = () => {
    setShowPasswordPrompt(false); 
  };



  const theme = createTheme({
    palette: {
      primary: {
        main: '#ec7c04'
      },

      secondary: {
        main: '#243474'
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <Header />
            <Routes>
              <Route exact path="/" element={<LogoPage />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/tutorials" element={<Tutorials />} />
              <Route exact path="/templates" element={<TemplatesV2 />} />
              <Route exact path="/dentally_auth_callback" element={<LoginRedirect />} />
              <Route exact path="/plans" element={<Plans />} />
              <Route exact path="/expired" element={<Expired />} />
              <Route exact path="/my-plan" element={<MyPlan />} />
              <Route exact path="/order/success" element={<PurchaseConfirmation />} />
              <Route exact path="/order/cancel" element={<Error />} />
              <Route exact path="/contact-sale" element={<CorporateForm />} />
              <Route exact path="/purchase-details" element={<PurchaseDetailsForm />} />
              <Route exact path=":consentBundleId/thankyou" element={<Thankyou />} />
              <Route exact path=":consentBundleId/summary" element={<Summary />} />
              <Route path=":consentBundleId" element={<ConsentDetails />} />
              <Route path="/video-gallery" element={<VideoManager />} />
              <Route path="/capture" element={<Capture />} />
              
              {/* admin */}
              <Route
                path="/homepage_admin"
                element={isAuthenticated ? <HomepageInternal /> : <Navigate to="/password_admin" />}
              />
              
               <Route
                path="/compliance_management"
                element={isAuthenticated ? <CompliancePage /> : <Navigate to="/password_admin" />}
              />
               <Route
                path="/existing_users"
                element={isAuthenticated ? <ExistingUsersPage /> : <Navigate to="/password_admin" />}
              />
              <Route
                path="/new_users"
                element={isAuthenticated ? <NewUsersPage /> : <Navigate to="/password_admin" />}
              />
               <Route
                path="/patient_engagement"
                element={isAuthenticated ? <PatientPage /> : <Navigate to="/password_admin" />}
              />

              <Route
                path="/user_feedback"
                element={isAuthenticated ? <UserFeedback /> : <Navigate to="/password_admin" />}
              />

              <Route
                path="/user_waitlist"
                element={isAuthenticated ? <Waitlist /> : <Navigate to="/password_admin" />}
              />

              <Route
                path="/user_Logins"
                element={isAuthenticated ? <UserLogin /> : <Navigate to="/password_admin" />}
              />

              <Route
                path="/help&support"
                element={isAuthenticated ? <HelpPage /> : <Navigate to="/password_admin" />}
              />

              <Route
                path="/user_List"
                element={isAuthenticated ? <UserList /> : <Navigate to="/password_admin" />}
              />
               <Route
                path="/completed-Consent-bundles"
                element={isAuthenticated ? <CompletedConsentBundles /> : <Navigate to="/password_admin" />}
              />

              {/* user */}
              <Route
                path="/homepage_user"
                element={isAuthenticated ? <HomepageInternalUser /> : <Navigate to="/password_user" />}
              />
              <Route
                path="/compliance_management_user"
                element={isAuthenticated ? <CompliancePageUser /> : <Navigate to="/password_user" />}
              />
              <Route
                path="/patient_engagement_user"
                element={isAuthenticated ? <PatientPageUser /> : <Navigate to="/password_user" />}
              />
              <Route
                path="/compliance_leaderboard_user"
                element={isAuthenticated ? <ComplianceLeaderboardUser /> : <Navigate to="/password_user" />}
              />
              <Route
                path="/help&support_user"
                element={isAuthenticated ? <HelpPageUser /> : <Navigate to="/password_user" />}
              />

           

              <Route
                path="/password_admin"
                element={
                  showPasswordPrompt ? (
                    <PasswordPrompt onSubmit={handlePasswordSubmit} onCancel={handlePasswordCancel} />
                  ) : (
                    <Navigate to="/homepage_admin" /> 
                  )
                }
              />

              <Route
                path="/password_user"
                element={
                  showPasswordPrompt ? (
                    <PasswordPrompt onSubmit={handlePasswordSubmit} onCancel={handlePasswordCancel} />
                  ) : (
                    <Navigate to="/homepage_user" /> 
                  )
                }
              />

             

              <Route path="/*" element={<Navigate to="/dashboard" />} /> 
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;