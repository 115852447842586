import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import styles from "./ImageGallery.module.scss";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { Chip } from "@mui/material";
import Lottie from "lottie-react";
import videoLoadingAnimation from "../animation/Animation - 1741783692232.json";

const ImageGalleryV2 = ({ showGallery, onClose }) => {
  const [activeTab, setActiveTab] = useState("stock_images");
  const [galleryData, setGalleryData] = useState({
    stock_gifs: [],
    stock_images: [],
    private: [],
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [isConfidential, setIsConfidential] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [imageName, setImageName] = useState("");
  const [matchingCategories, setMatchingCategories] = useState([])
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryImagesMap, setCategoryImagesMap] = useState({});

  const fetchGalleryData = async (searchQuery = "") => {
    try {
      const token = axios.defaults.headers.common.Authorization;
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }
      setLoadingSearch(true);
      const response = await axios.get(
        `consent_clause/get_user_media?search=${searchQuery}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setGalleryData(response.data);
      setImageName("");
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchGalleryData(searchQuery);
    }, 500); 

    return () => clearTimeout(debounceTimeout);
  }, [searchQuery]);


  const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };



  const fetchCategories = async () => {
    try {
     
      const { data } = await axios.post(`/treatment_category`);
      setCategories(data);
     
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };


  useEffect(() => {
    fetchCategories();
  }, []);


  useEffect(() => {
    if (categories.length === 0 || !galleryData) return;
  
    const newCategoryImagesMap = {};
  
    const categorizeItems = (items, type) => {
      items.forEach((item) => {
        const categoryName = categories.find(
          (category) => category.id === item.category_id
        )?.category_name;
  
        if (categoryName) {
          if (!newCategoryImagesMap[categoryName]) {
            newCategoryImagesMap[categoryName] = {
              stock_gifs: [],
              stock_images: [],
              private: [],
            };
          }

          if (type === "private") {
            newCategoryImagesMap[categoryName][type].push(item);
          } else if (type !== "private" && !galleryData.private.some(p => p.id === item.id)) {
            newCategoryImagesMap[categoryName][type].push(item);
          }
        }
      });
    };

    categorizeItems(galleryData.stock_gifs, "stock_gifs");
    categorizeItems(galleryData.stock_images, "stock_images");
    categorizeItems(galleryData.private, "private");

    Object.keys(newCategoryImagesMap).forEach((categoryName) => {
      const categoryData = newCategoryImagesMap[categoryName];
      if (!categoryData.stock_gifs.length) delete categoryData.stock_gifs;
      if (!categoryData.stock_images.length) delete categoryData.stock_images;
      if (!categoryData.private.length) delete categoryData.private;
    });
  
    setCategoryImagesMap(newCategoryImagesMap);
    setFilteredImages([]);
  }, [categories, galleryData]);
  
  
  
  
  
  
  


  const handleCategoryClick = (categoryName) => {
    if (categoryName === "All") {
      setSelectedCategory(null);
      setFilteredImages([]); 
    } else if (selectedCategory === categoryName) {
      setSelectedCategory(null);
      setFilteredImages([]); 
    } else {
      setSelectedCategory(categoryName);

      const imagesForCategory = [
        ...(activeTab === "stock_gifs" ? categoryImagesMap[categoryName]?.stock_gifs || [] : []),
        ...(activeTab === "stock_images" ? categoryImagesMap[categoryName]?.stock_images || [] : []),
        ...(activeTab === "private" ? categoryImagesMap[categoryName]?.private || [] : []),
      ];
  
      setFilteredImages(imagesForCategory);
    }
  };

  const renderImages = () => {
    const imagesToRender = selectedCategory ? filteredImages : galleryData[activeTab];
  
    return imagesToRender.map((item) => {
      const keywords = typeof item.keywords === "string"
        ? item.keywords.split(",").map((keyword) => keyword.trim())
        : [];
  
      return (
        <div key={item.id} className={styles.imageItemContainer}>
          <div className={styles.imageItem}>
            <div className={styles.imageWrapper}>
              <Tooltip title={keywords.join(", ")}>
                <div className={styles.imageContainer}>
                  <img
                    src={item.url}
                    alt={item.name}
                    className={styles.galleryImage}
                  />
                </div>
              </Tooltip>
  
              {/* Duration Overlay */}
              {item.duration && (
                <div className={styles.durationOverlay}>
                  {formatDuration(item.duration)}
                </div>
              )}
  
              {/* Confidential Icon */}
              {item.is_confidential && (
                <Tooltip title="Confidential">
                  <GppMaybeIcon className={styles.confidentialIcon} />
                </Tooltip>
              )}
            </div>
          </div>
  
          {/* Delete Button for Psrivate Tab */}
          {activeTab === "private" && (
            <IconButton
              className={styles.deleteButton}
              onClick={() => handleDeleteImage(item.id)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      );
    });
  };
  
  const handleDeleteImage = async (mediaId) => {
    try {
      const token = axios.defaults.headers.common.Authorization;
      if (!token) {
        toast.error("Authorization token is missing.");
        return;
      }
      await axios.put(
        `/consent_clause/archive_user_media/${mediaId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Media archived successfully.");
      fetchGalleryData();
    } catch (error) {
      console.error("Error archiving media:", error);
      toast.error("Failed to archive media.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImages((prevImages) => [...prevImages, ...acceptedFiles]);
    },
    multiple: true,
  });

  const removeImage = (index) => {
    const newImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(newImages);
  };

  const handleImageUpload = async () => {
    const token = axios.defaults.headers.common.Authorization;
    setLoading(true);

    if (uploadedImages.length > 0) {
      try {
        for (const file of uploadedImages) {
          const fileSizeInMB = file.size / (1024 * 1024);
          if (fileSizeInMB > 2) {
            toast.error("Image is too large, more than 2MB.");
            return; 
          }

          const formData = new FormData();
          formData.append("file", file);
        
        formData.append("user_file_name", imageName);
          formData.append("keywords", keywords);  
          formData.append("category_id", selectedCategory);  

          await axios.post("consent_clause/upload_user_media_to_s3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          });
        }

        toast.success("Content added to the Image gallery");
        fetchGalleryData();
        closeImageModal();
      } catch (error) {
        console.error("Error uploading image:", error);
        // toast.error("Error uploading images");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("No images to upload.");
      setLoading(false);
    }
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setUploadedImages([]);
    setIsConfidential(false);
  };

  useEffect(() => {
    if (showGallery) fetchGalleryData();
  }, [showGallery]);

  if (!showGallery) return null;

  return (
    <div>
      <div className={styles.header}>
  <button
    type="button"
    onClick={fetchGalleryData}
    className={styles.refreshButton}
  >
    <RefreshIcon />
  </button>
  <h3 className={styles.topic}>Image Gallery</h3>
  <div className={styles.actions}>
    <Button
      variant="contained"
      onClick={() => setIsImageModalOpen(true)}
      className={styles.uploadButton}
    >
      +
      <PhotoCameraBackIcon
        style={{ marginLeft: "10px", fontSize: "20px" }}
      />
    </Button>
  </div>
</div>

{/* tbs Section */}
<div className={styles.tabContainer}>
  {["stock_images", "stock_gifs", "private"].map((tab) => (
    <button
      key={tab}
      type="button"
      className={`${styles.tabButton} ${
        activeTab === tab ? styles.activeTab : ""
      }`}
      onClick={() => {
        setActiveTab(tab);
        if (selectedCategory !== "All") {
          handleCategoryClick("All");
        }
      }}
    >
      {tab.replace("_", " ").toUpperCase()}
    </button>
  ))}
</div>

{/* catogaty chip Section */}
<div className={styles.categoryChipsContainer}>
  {/* Show the "All" button only if there are categories with images in the current active tab */}
  {Object.keys(categoryImagesMap).some((categoryName) => {
    const hasStockImages =
      (categoryImagesMap[categoryName]?.stock_images || []).length > 0;
    const hasPrivateImages =
      (categoryImagesMap[categoryName]?.private || []).length > 0;
    const hasStockGifs =
      (categoryImagesMap[categoryName]?.stock_gifs || []).length > 0;

    // Only show "All" if there are images for the current active tab
    return (
      (activeTab === "stock_images" && hasStockImages) ||
      (activeTab === "stock_gifs" && hasStockGifs) ||
      (activeTab === "private" && hasPrivateImages)
    );
  }) && (
    <button
      type="button"
      className={`${styles.categoryChip} ${
        selectedCategory === "All" ? styles.selectedCategory : ""
      }`}
      onClick={() => handleCategoryClick("All")}
    >
      All
    </button>
  )}

  {/* Category Chips */}
  {Object.keys(categoryImagesMap).map((categoryName) => {
    const hasStockImages =
      (categoryImagesMap[categoryName]?.stock_images || []).length > 0;
    const hasPrivateImages =
      (categoryImagesMap[categoryName]?.private || []).length > 0;
    const hasStockGifs =
      (categoryImagesMap[categoryName]?.stock_gifs || []).length > 0;

    const shouldDisplayCategoryChip =
      (activeTab === "stock_images" && hasStockImages) ||
      (activeTab === "stock_gifs" && hasStockGifs) ||
      (activeTab === "private" && hasPrivateImages);

    if (!shouldDisplayCategoryChip) return null;

    return (
      <button
        key={categoryName}
        type="button"
        className={`${styles.categoryChip} ${
          selectedCategory === categoryName ? styles.selectedCategory : ""
        }`}
        onClick={() => handleCategoryClick(categoryName)}
      >
        {categoryName}
      </button>
    );
  })}
</div>



{/* Search Bar */}
<div>
  {(activeTab === "stock_images" || activeTab === "stock_gifs") && (
    <div className={styles.searchBarContainer}>
      <input
        type="text"
        placeholder="Search..."
        className={styles.searchInput}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  )}

  {loadingSearch && <p>Loading...</p>}

  <div className={styles.imageGallery}>{renderImages()}</div>
</div>

      
      <ImageUploadModal
        open={isImageModalOpen}
        onClose={closeImageModal}
        onUpload={handleImageUpload}
        uploadedImages={uploadedImages}
        onRemoveImage={removeImage}
        getInputProps={getInputProps}
        isConfidential={isConfidential}
        imageName={imageName}
        setImageName={setImageName} 
        getRootProps={getRootProps}
        setIsConfidential={setIsConfidential}
        fetchGalleryData={fetchGalleryData}
        closeImageModal={closeImageModal}
      />
      <LoadingModal open={loading} />
    </div>
  );
};

const ImageGalleryTooltip = () => (
  <div>
    <Typography variant="h6" style={{ marginBottom: "10px" }}>
      Image Gallery :
    </Typography>
    <List style={{ fontSize: "16px" }}>
      <ListItem>
        <ListItemText primary="Upload directly from the upload image button." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Add image to editor and save; it will automatically be added to the gallery." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Use your mobile to capture and upload images (Coming soon!)." />
      </ListItem>
    </List>
  </div>
);

const ImageUploadModal = ({
    open,
    onClose,
    uploadedImages,
    onRemoveImage,
    getInputProps,
    getRootProps,
    isConfidential,
    setIsConfidential,
    imageName,
    setImageName,
    fetchGalleryData,
    closeImageModal
}) => {
  
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.post(`/treatment_category`);
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (open) {
      fetchCategories();
    }
  }, [open]);

  const resizeImage = (file: File, width: number, height: number): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
  
      reader.onload = () => {
        img.onload = () => {
         
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (!ctx) {
            reject("Canvas context not found");
            return;
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const resizedFile = new File([blob], file.name, { type: file.type });
                resolve(resizedFile);
              } else {
                reject("Failed to resize the image");
              }
            },
            file.type,
            1
          );
        };
        img.src = reader.result as string;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); 
    });
  };
  
  const handleImageUpload = async () => {
    const token = axios.defaults.headers.common.Authorization;
    setLoading(true);

    if (uploadedImages.length > 0) {
      try {
        for (const file of uploadedImages) {
          const fileSizeInMB = file.size / (1024 * 1024);
          if (fileSizeInMB > 2) {
            toast.error("Image is too large, more than 2MB.");
            return;
          }

          const resizedFile = await resizeImage(file, 300, 202);
          const formData = new FormData();
          formData.append("file", resizedFile);
          formData.append("is_confidential", isConfidential ? "true" : "false");
          formData.append("user_file_name", imageName);
          formData.append("keywords", keywords); 
          formData.append("category_id", selectedCategory); 

          await axios.post("consent_clause/upload_user_media_to_s3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          });
        }

        toast.success("Content added to the Image gallery");

        setCategories([]);            // Clear categories
        setSelectedCategory("");      // Clear selected category
        setKeywords("");   

        fetchGalleryData();
        closeImageModal();
      } catch (error) {
        console.error("Error uploading image:", error);
       
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("No images to upload.");
      setLoading(false);
    }
  };


  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "30px",
          borderRadius: "8px",
          boxShadow: 24,
          minWidth: "1000px",
          minHeight: "900px",
          maxWidth: "1000px",
          maxHeight: "900px",
          overflow: "auto",
        }}
      >
        <h2>Upload an Image to Gallery</h2>

        <input
          type="text"
          placeholder="Enter image name..."
          value={imageName}
          onChange={(e) => {
            setImageName(e.target.value); 
          }}
          style={{
            width: "97%",
            padding: "14px",
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            fontSize: "18px",
            backgroundColor: "#f9f9f9",
            transition: "all 0.3s ease-in-out",
          }}
          onFocus={(e) => (e.target.style.borderColor = "#0056b3")}
          onBlur={(e) => (e.target.style.borderColor = "#ddd")}
          required
        />

        {/* Category Dropdown */}
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={{
            width: "100%",
            padding: "14px", // Increased padding
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            fontSize: "18px", // Increased font size
            backgroundColor: "#f9f9f9",
            transition: "all 0.3s ease-in-out",
          }}
          onFocus={(e) => (e.target.style.borderColor = "#0056b3")}
          onBlur={(e) => (e.target.style.borderColor = "#ddd")}
          required
        >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.category_name}
            </option>
          ))}
        </select>

        {/* Keywords Input */}
        <input
          type="text"
          placeholder="Enter keywords (comma-separated)..."
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
          style={{
            width: "97%",
            padding: "14px",
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            fontSize: "18px",
            backgroundColor: "#f9f9f9",
            transition: "all 0.3s ease-in-out",
          }}
          onFocus={(e) => (e.target.style.borderColor = "#0056b3")}
          onBlur={(e) => (e.target.style.borderColor = "#ddd")}
          required
          placeholder="e.g., porcelain bonded bridge, porcelain bonded pontic"
        />

        {/* Dropzone */}
        <div {...getRootProps()} style={{
            border: "2px dashed #003366",
            padding: "60px",
            textAlign: "center",
            borderRadius: "8px",
            backgroundColor: "#f8f8f8",
            marginBottom: "20px",
          }}
        >
          <p>Drag and drop image/Gif here (accepted formats: .jpg, .jpeg, .gif, .png)</p>
          <p>or</p>
          <p>Click to upload image/Gif</p>
        </div>

        {/* Image Preview */}
        <div style={{
            marginTop: "20px",
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {uploadedImages.map((file, index) => (
            <div key={index} style={{ position: "relative", margin: "10px" }}>
              <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                style={{
                  maxWidth: "900px",
                  maxHeight: "200px",
                  objectFit: "contain",
                  marginBottom: "10px",
                }}
              />
              <Button
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  padding: "5px",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  color: "white",
                  borderRadius: "10%",
                }}
                onClick={() => onRemoveImage(index)}
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>

        {/* Confidential Checkbox */}
        <div style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label style={{ marginBottom: "10px" }}>
            <input
              type="checkbox"
              checked={isConfidential}
              onChange={(e) => setIsConfidential(e.target.checked)}
            />
            Mark this image as confidential
          </label>
        </div>

        {/* Buttons */}
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              padding: "12px 20px",
              borderRadius: "8px",
              flex: 1,
              marginRight: "10px",
            }}
            onClick={handleImageUpload}
            disabled={uploadedImages.length === 0 || loading}
          >
            {loading ? "Uploading..." : "Upload Image"}
          </Button>
          <Button
            variant="outlined"
            style={{
              padding: "12px 20px",
              borderRadius: "8px",
              flex: 1,
              marginLeft: "10px",
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const LoadingModal = ({ open }) => (
  <Modal open={open}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "30px",
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <CircularProgress size={50} />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Uploading data to cloud storage...
      </Typography>
    </Box>
  </Modal>
);

export default ImageGalleryV2;
